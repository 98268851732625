
:root {
  --primary: #00bee7;
  --secondary: #223944;
  --tertiary: #f1e405;
  --success: #36d473;
  --info: #d8e3e8;
  --warning: #ff9b13;
  --danger: #e46924;
  --highlight: #c9ecf6;
  --error: #eb1c26;
  --green-tea: #7ed321;
  --flat-green: #5cb85c;

  font-family: sans-serif;
  color: #000;
  background: #fafafa;
  font-size: 1.6em;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  padding:0;
  margin:0;
}

h1 {
  font-family: "Antenna","Helvetica Neue",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 1em;
  font-weight:300;
}

header {
  background: var(--primary);
  display: grid;
  grid-template-columns: 50px auto 100px;
  align-items: center;
  padding: 0.3em;
  
  & svg {
    height: 60px;
    width: 40px;
    color: #000;
  }
  & p {
    font-size: 0.6em;
    color: #efefef;
    margin: 0.5em;
    padding: 0.5em;
  }
}
footer {
  background: #1c2d36;
  font-family: "Antenna","Helvetica Neue",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-weight: 300;
  font-size: 0.6em;
  color: #ffffff90;
  padding: 0.3em 1.5em;
}

form {
  margin: 0 2em;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  padding: 0;
  min-height: calc(100vh - 170px);
}

.form-control {
  font-weight: 300;

  & > label, & > input, & > textarea, & > legend {
    display: block;
    width: 100%;
    margin: 0.5em 0;
  }

  & label, & legend {
    line-height: 1.5;
  }

  & input[type="text"] {
    font-family: monospace;
    font-size: 1.5em;
    color: var(--secondary);
    outline: 0;
    border: 0;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    padding: 0.3em;
    border-radius: 4px;
    background: white;
    width: 100%;

    &::placeholder {
      font-family: sans-serif;
      font-weight: 300;
      font-size: 0.5em;
    }

    &:focus{
      box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
      border: 1px solid var(--primary);
      transition: all 150ms ease;
    }

    &:read-only {
      color: #999;
      border-bottom: none;
    }
  }

  & p {
    display: none;
    visibility: hidden;
  }

  &.error {
    color: var(--error);
    & input:focus {
      border: 1px solid var(--error);
    }
    & input::placeholder {
      color: var(--error);
    }

    & p {
      display: block;
      visibility: visible;
      font-size: 0.85em;
      padding: 0;
      margin: 0.8em 0;
    }
  }

  &.autocomplete {
    display: none;
    visibility: hidden;
  }
}

dl {
  display: flex;
  flex-flow: row wrap;
  margin:0;
  padding: 0;
  
  & .prop {
    margin: 1em;
  }
  &:margin-after{
    box-sizing: inherit;
  }

  & dt {
    font-weight: 300;
    line-height: 1.5;
  }

  & dd {
    font-family: monospace;
    font-size: 1.2em;
    padding: 0;
    margin: 0;
  }
}

button {
  display: inline-block;
  white-space: nowrap;
  outline: 0;
  font-family: sans-serif;
  font-size: 1em;
  font-weight: 600;
  line-height: 2.5;
  color:  #fff;
  text-transform: capitalize;
  transition: all 0.2s ease;
  border: 1px solid #33333310;
  border-radius:3px;
  padding: 0 1.5em;
  background: #000;
  vertical-align: middle;
  cursor: pointer;
  transition: all 0.2s ease 0s;

  &:hover,
  &:active {
    background: var(--primary);
    color: #fff;
    text-decoration: none;
  }

  &:disabled {
    cursor: default;
    opacity: 0.4;
  }

  &#reset {
    color: #000;
    background: #efefef;
  }
  &#submit {
    background: var(--primary);
    color: #fff;
  }

}
.actions {
    margin: 1em;
    display: flex;
    flex-flow: row-reverse nowrap;
    align-items: center;
    justify-content: space-between;
  /* & button {
    margin: 1em;
  } */
}

#notice-board {
  margin: 0;
  padding: 1em;
  &.error {
    background: var(--error);
    color: #fff;
  }
  &.warning {
    background: var(--warning);
    color: #333;
  }
  &.success {
    background: var(--green-tea);
    color: #fff;
  }
  &.fail {
    background: var(--danger);
    color: #fff;
  }
}

.loading {
  width: 100%;
  text-align: center;
  margin: 1em 0;

  & svg {
    width: 60px;
    height: 30px;
  }

  & circle {
    fill: #2a96ed;
    animation-name: upAndDown;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(.05, .2, .35, 1);
    animation-iteration-count: infinite;

    &:nth-child(2) {
      animation-delay: .18s;
    }

    &:nth-child(3) {
      animation-delay: .36s;
    }
  }

}



@keyframes upAndDown {
  0% { opacity: 0; transform: translateY(0); }
  25% { opacity: 1; transform: translateY(-10px); }
  75% { opacity: 1; transform: translateY(-10px); }
  100% { opacity: 0; transform: translateY(0); }
}